import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'

import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'

import { createBrowserHistory } from 'history'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router'

import { user } from './reducers/user'
import { session } from './reducers/session'
import { frames } from './reducers/frames'
import { calendars } from './reducers/calendars'
import { frameUsers } from './reducers/frameUsers'
import { passwordResets } from './reducers/passwordResets'
import { messages } from './reducers/messages'
import { groceryItems } from './reducers/groceryItems'
import { calendarEvents } from './reducers/calendarEvents'
import { webcalAccounts } from './reducers/webcalAccounts'
import { plusPermissions } from './reducers/plusPermissions'
import { purchaseMetadata } from './reducers/purchaseMetadata'
import { reminderProfiles } from './reducers/reminderProfiles'
import { categories } from './reducers/categories'
import { plus } from './reducers/plus'

// Admin Reducers
import { deployments } from './reducers/admin/deployments'
import { frameMigration } from './reducers/admin/frameMigration'
import { users } from './reducers/admin/users'
import { androidVersions } from './reducers/admin/androidVersions'
import { adminMessages } from './reducers/admin/messages'
import { fleetHealths } from './reducers/admin/fleetHealths'
import { deviceGroups } from './reducers/admin/deviceGroups'

// User Containers
import DashboardContainer from './containers/DashboardContainer'
import RegisterContainer from './containers/RegisterContainer'
import LogInContainer from './containers/LogInContainer'
import LogInWithGoogleContainer from './containers/LogInWithGoogleContainer'
import ResetPasswordContainer from './containers/ResetPasswordContainer'
import NewFrameContainer from './containers/NewFrameContainer'
import FrameContainer from './containers/FrameContainer'
import NewMessageContainer from './containers/NewMessageContainer'
import EditUserContainer from './containers/EditUserContainer'
import PlusLinkContainer from './containers/PlusLinkContainer'
import PlusShareContainer from './containers/PlusShareContainer'
import MessageModalOrMessagePage from './components/MessageModalOrMessagePage'

// Admin Containers
import DeploymentDeliverabilityContainer from './containers/admin/DeploymentDeliverabilityContainer'
import ExportContainer from './containers/admin/ExportContainer'
import AdminNewFrameContainer from './containers/admin/AdminNewFrameContainer'
import AdminNewPlusUserContainer from './containers/admin/AdminNewPlusUserContainer'
import UsersContainer from './containers/admin/UsersContainer'
import UserContainer from './containers/admin/UserContainer'
import RequestApproval from './components/admin/RequestApproval'
import AndroidVersions from './components/admin/AndroidVersions'
import AndroidVersion from './components/admin/AndroidVersion'
import SpamApproval from './components/admin/SpamApproval'
import NewDeploymentIntent from './components/admin/NewDeploymentIntent'
import DeploymentIntent from './components/admin/DeploymentIntent'
import FleetHealthList from './components/admin/FleetHealthList'
import FleetHealthComparison from './components/admin/FleetHealthComparison'

import CheckYourEmail from './components/CheckYourEmail'
import MobilePromoPage from './components/MobilePromoPage'
import RedeemShareToken from './components/RedeemShareToken'

import api from './middleware/api'
import MonthInReviewPromoPage from './components/MonthInReviewPromoPage'
import DeleteAccountPage from './components/DeleteAccountPage'

const history = createBrowserHistory()
const middleware = routerMiddleware(history)
const allMiddlewares = applyMiddleware(middleware, api)

const reducers = combineReducers({
  user,
  session,
  frames,
  frameUsers,
  passwordResets,
  messages,
  plusPermissions,
  reminderProfiles,
  calendars,
  groceryItems,
  calendarEvents,
  webcalAccounts,
  categories,
  purchaseMetadata,
  plus,
  // Admin Reducers
  deployments,
  fleetHealths,
  androidVersions,
  frameMigration,
  users,
  adminMessages,
  deviceGroups,
  router: connectRouter(history),
})

// https://github.com/reduxjs/redux-devtools/tree/main/extension#12-advanced-store-setup
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, {}, composeEnhancers(allMiddlewares))

const UPLOAD_PROGRESS_ALERT =
  'You have photo uploads in progress. If you leave the page now, they may not get delivered.'
window.addEventListener('beforeunload', (event) => {
  const state = store.getState()
  if (state.messages.uploading) {
    event.preventDefault()
    event.returnValue = UPLOAD_PROGRESS_ALERT
    return UPLOAD_PROGRESS_ALERT
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('root')
  if (element) {
    ReactDOM.render(
      <Provider store={store}>
        {/* ConnectedRouter will use the store from Provider automatically */}
        <ConnectedRouter history={history}>
          <Switch>
            <Route exact path="/" component={DashboardContainer} />
            <Route path="/activate" component={RegisterContainer} />
            <Route path="/finalstep" component={RegisterContainer} />
            <Route path="/register" component={RegisterContainer} />
            <Route path="/log-in/:token?" component={LogInContainer} />
            <Route path="/reset-password/:email?" component={ResetPasswordContainer} />
            <Route path="/check-your-email" component={CheckYourEmail} />
            <Route path="/user/edit" component={EditUserContainer} />
            <Route path="/delete-account" component={DeleteAccountPage} />
            <Route path="/link-plus-account" component={PlusLinkContainer} />
            <Route path="/share-plus-account" component={PlusShareContainer} />
            <Route path="/frames/new" component={NewFrameContainer} />
            <Route path="/download-app" component={MobilePromoPage} />
            <Route path="/month-in-review" component={MonthInReviewPromoPage} />
            <Route
              path="/frames/:id/:action(activate|sync|sync-success|sync-indv|sync-failure|plus|plus-success|plus-reject|friends|privacy|privacy-settings|final-step)"
              component={FrameContainer}
            />
            <Route path="/frames/:id/messages" component={MessageModalOrMessagePage} />
            <Route path="/messages/new" component={NewMessageContainer} />
            <Route path="/frame-access/:frameId/:shareToken" component={RedeemShareToken} />
            {/* Admin Routes */}
            <Route path="/admin-dash/users/:id" component={UserContainer} />
            <Route path="/admin-dash/users" component={UsersContainer} />
            <Route path="/admin-dash/new-plus-user" component={AdminNewPlusUserContainer} />
            <Route
              path="/admin-dash/deployments/:id"
              component={DeploymentDeliverabilityContainer}
            />
            <Route path="/admin-dash/versions/:id/deploy" component={NewDeploymentIntent} />
            <Route
              path="/admin-dash/versions/:versionId/deployment-intents/:id"
              component={DeploymentIntent}
            />
            <Route path="/admin-dash/versions/:id" component={AndroidVersion} />
            <Route path="/admin-dash/versions" component={AndroidVersions} />
            <Route path="/admin-dash/export" component={ExportContainer} />
            <Route
              path="/admin-dash/fleet-health/:controlId/vs/:experimentalId"
              component={FleetHealthComparison}
            />
            <Route path="/admin-dash/fleet-health" component={FleetHealthList} />
            <Route path="/admin-dash/spam-approval/:id" component={SpamApproval} />
            <Route path="/admin-dash/frames/:id/request-approval" component={RequestApproval} />
            <Route path="/admin-dash/frames/new" component={AdminNewFrameContainer} />
            <Route path="/admin/sign_in" component={LogInWithGoogleContainer} />
            <Route path="/admin" component={DashboardContainer} />
          </Switch>
        </ConnectedRouter>
      </Provider>,
      element
    )
  }
})
