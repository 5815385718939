import { LOG_IN_SUCCESS, LOG_OUT_SUCCESS } from '../actions/session'

import {
  FRAMES_CREATE_REQUEST,
  FRAMES_CREATE_SUCCESS,
  FRAMES_CREATE_FAILURE,
  FRAMES_SHOW_REQUEST,
  FRAMES_SHOW_SUCCESS,
  FRAMES_REQUEST,
  FRAMES_SUCCESS,
  FRAMES_UPDATE_REQUEST,
  FRAMES_UPDATE_SUCCESS,
  FRAMES_UPDATE_FAILURE,
  FRAMES_PRIVACY_UPDATE_FINISHED,
  FRAMES_RESET_SUCCESS,
  UPDATE_SELECTED_FRAMES,
  FRAMES_MESSAGE_COUNTS_SUCCESS,
  FRAMES_SHOW_META_SUCCESS,
  FRAMES_SHOW_META_REQUEST,
  FRAMES_COPY_MESSAGES_REQUEST,
  FRAMES_COPY_MESSAGES_SUCCESS,
  FRAMES_COPY_MESSAGES_FAILURE,
  CLEAR_LOCAL_FRAMES,
  FRAME_ACTIVATION_CODE_REQUEST,
  FRAME_ACTIVATION_CODE_SUCCESS,
} from '../actions/frames'

import { TRANSFER_FRAME_USER_SUCCESS } from '../actions/admin/frameMigration'

const DEFAULT_STATE = {
  hasBulkFeatures: false,
  canReset: false,
  trialDaysRemaining: null,
  presence: null,
  gettingFrames: false,
  creatingFrame: false,
  updatingFrame: false,
  successfulPrivacyUpdate: false,
  newFrame: null,
  frame: null,
  frames: [],
  numPages: null,
  upsellMeta: null,
  errors: [],
  users: [],
  selectedFrameIds: [],
  messageCounts: {},
  transferMessagesStatus: null,
  showSearchEmptyState: true,
  activationCode: null,
}

export function frames(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case FRAMES_CREATE_REQUEST: {
      return { ...state, creatingFrame: true, newFrame: null, errors: [] }
    }
    case FRAMES_CREATE_SUCCESS: {
      return { ...state, newFrame: action.response.data, creatingFrame: false }
    }
    case FRAMES_UPDATE_REQUEST: {
      return { ...state, updatingFrame: true, errors: [] }
    }
    case FRAMES_CREATE_FAILURE:
    case FRAMES_UPDATE_FAILURE: {
      return { ...state, creatingFrame: false, updatingFrame: false, errors: action.errors }
    }
    case FRAMES_SHOW_META_REQUEST: {
      return { ...state, presence: null }
    }
    case FRAMES_SHOW_META_SUCCESS: {
      return { ...state, presence: action.response.data.attributes.presence }
    }
    case FRAMES_SHOW_REQUEST: {
      return { ...state, frame: null, newFrame: null }
    }
    case FRAMES_SHOW_SUCCESS: {
      return {
        ...state,
        frame: action.response.data,
        newFrame: null,
        hasBulkFeatures: !!action.response.meta && !!action.response.meta.bulk_features,
        trialDaysRemaining: !!action.response.meta && action.response.meta.trial_days_remaining,
      }
    }
    case FRAMES_REQUEST: {
      return { ...state, gettingFrames: true, frames: [], users: [], selectedFrameIds: [] }
    }
    case FRAMES_PRIVACY_UPDATE_FINISHED: {
      return { ...state, successfulPrivacyUpdate: false }
    }
    case FRAMES_SUCCESS: {
      const newState = {
        gettingFrames: false,
        frames: action.response.data,
        showSearchEmptyState: false,
      }
      const { meta } = action.response
      if (!!meta) {
        newState.hasBulkFeatures = !!meta.bulk_features
        newState.canReset = !!meta.can_reset
        newState.currentPage = meta.current_page
        newState.numPages = meta.num_pages
        newState.trialDaysRemaining = meta.trial_days_remaining
      }
      if (action.response.included) newState.users = action.response.included
      return { ...state, ...newState }
    }
    case FRAMES_RESET_SUCCESS:
    case FRAMES_UPDATE_SUCCESS:
    case TRANSFER_FRAME_USER_SUCCESS: {
      const updatedFrame = action.response.data
      const updatedFrames = state.frames.slice(0)
      updatedFrames.forEach((frame, i, a) => {
        if (updatedFrame.id === frame.id) a[i] = updatedFrame
      })
      const { frame } = state
      if (frame && frame.id === updatedFrame.id) {
        frame.attributes = updatedFrame.attributes
      }
      const updatedState = {
        frame,
        frames: updatedFrames,
        successfulPrivacyUpdate: true,
        updatingFrame: false,
        errors: [],
      }
      const { included } = action.response
      if (included) updatedState.users = state.users.concat(included)
      return { ...state, ...updatedState }
    }
    case UPDATE_SELECTED_FRAMES: {
      return { ...state, selectedFrameIds: action.selectedFrameIds.slice(0) }
    }
    case FRAMES_MESSAGE_COUNTS_SUCCESS: {
      const count = action.response.data
      const previousMessageCounts = { ...state.messageCounts }
      previousMessageCounts[count.id] = count.attributes
      return { ...state, messageCounts: previousMessageCounts }
    }
    case FRAMES_COPY_MESSAGES_SUCCESS: {
      return {
        ...state,
        transferMessagesStatus: 'success',
      }
    }
    case FRAMES_COPY_MESSAGES_FAILURE: {
      return {
        ...state,
        transferMessagesStatus: 'failure',
      }
    }
    case CLEAR_LOCAL_FRAMES: {
      return {
        ...state,
        frames: [],
        numPages: 1,
        showSearchEmptyState: true,
      }
    }
    case FRAME_ACTIVATION_CODE_REQUEST: {
      return { ...state, activationCode: null }
    }
    case FRAME_ACTIVATION_CODE_SUCCESS: {
      const { code } = action.response.data.attributes
      return { ...state, activationCode: code }
    }
    case LOG_IN_SUCCESS:
    case LOG_OUT_SUCCESS: {
      return { ...state, ...DEFAULT_STATE }
    }
    default:
      return state
  }
}
