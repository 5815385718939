import { push } from 'connected-react-router'

import { LOG_OUT_SUCCESS } from '../actions/session'
import { HttpWrapper } from '../utilities/HttpWrapper'
import { Session } from '../utilities/Session'

export default (store) => (next) => (action) => {
  const callAPI = action.api

  // So the middleware doesn't get applied to every single action
  if (typeof callAPI === 'undefined') return next(action)

  const { types, endpoint, method, data, authenticated, isAdmin } = callAPI

  const [requestType, successType, errorType] = types

  store.dispatch({ type: requestType })

  return HttpWrapper.call(endpoint, method, data, authenticated, isAdmin)
    .then((response) => {
      next({
        response: response.data,
        type: successType,
      })
    })
    .catch((err) => {
      const { response } = err
      if (response) {
        if (
          response.status === 401 ||
          (response.data.errors &&
            (response.data.errors[0] === 'Invalid token' || response.status === 404))
        ) {
          const afterLogOutPage = Session.afterLogOutPage()
          store.dispatch({ type: LOG_OUT_SUCCESS }) // Log out without an additional API call
          if (afterLogOutPage) store.dispatch(push(afterLogOutPage))
        }
        next({
          errors: response.data.errors,
          meta: response.data.meta,
          statusCode: response.status,
          type: errorType,
        })
      } else if (err.code === 'ECONNABORTED') {
        alert(
          'Your computer is no longer connected to the internet. Please check your WiFi or network connection and try again!'
        )
      } else {
        throw err
      }
    })
}
