import { LOG_OUT_SUCCESS } from '../actions/session'

import { STARTED_UPLOAD, FINISHED_UPLOAD, NEW_IN_PROGRESS_UPLOAD } from '../actions/uploadUrls'

import { CAPTION_DESTROY_SUCCESS, CAPTION_UPDATE_SUCCESS } from '../actions/captions'

import {
  MESSAGES_REQUEST,
  MESSAGES_SUCCESS,
  MESSAGES_FAILURE,
  MESSAGES_PRELOAD_REQUEST,
  MESSAGES_PRELOAD_SUCCESS,
  MESSAGES_APPEND_PRELOADED,
  MESSAGE_SHOW_REQUEST,
  MESSAGE_SHOW_SUCCESS,
  TOGGLE_SELECTED_MESSAGE,
  DESELECT_ALL_MESSAGES,
  MESSAGE_DESTROY_MULTIPLE_SUCCESS,
  MESSAGE_DESTROY_REQUEST,
  MESSAGE_DESTROY_SUCCESS,
  MESSAGE_DESTROY_ALL_SUCCESS,
} from '../actions/messages'

import { removeUploadedMessages } from '../utilities/removeUploadedMessages'

const PAYMENT_REQUIRED = 402

const DEFAULT_STATE = {
  gettingInitialMessages: false,
  nonePreloaded: true,
  message: null,
  preloadedMessages: [],
  pages: [{ messages: [] }],
  gettingUploadUrl: false,
  uploading: false,
  uploadUrls: [],
  frameOwner: false,
  currentPage: null,
  numPages: null,
  upsellMeta: null,
  errors: [],
  notifications: [],
  inProgressUploads: {},
  selectedMessageIds: [],
}

export function messages(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case MESSAGES_REQUEST: {
      return {
        ...state,
        preloadedMessages: [],
        pages: [{ messages: [] }],
        gettingInitialMessages: true,
        nonePreloaded: true,
        gettingUploadUrl: false,
        uploadUrls: [],
        frameOwner: false,
        currentPage: null,
        numPages: null,
        upsellMeta: null,
        selectedMessageIds: [],
      }
    }
    case MESSAGES_PRELOAD_REQUEST: {
      return { ...state, nonePreloaded: true, preloadedMessages: [] }
    }
    case MESSAGES_SUCCESS: {
      const { inProgressUploads } = state
      const { meta } = action.response
      return {
        ...state,
        pages: [{ messages: action.response.data }],
        gettingInitialMessages: false,
        frameOwner: meta.frame_owner,
        currentPage: meta.current_page,
        numPages: meta.num_pages,
        inProgressUploads: removeUploadedMessages(inProgressUploads, action.response.data),
      }
    }
    case MESSAGES_PRELOAD_SUCCESS: {
      const { meta } = action.response
      return {
        ...state,
        preloadedMessages: action.response.data,
        nonePreloaded: false,
        frameOwner: meta.frame_owner,
        currentPage: meta.current_page,
        numPages: meta.num_pages,
      }
    }
    case MESSAGES_APPEND_PRELOADED: {
      const pages = [...state.pages, { messages: [...state.preloadedMessages] }]
      return { ...state, nonePreloaded: true, preloadedMessages: [], pages }
    }
    case MESSAGES_FAILURE: {
      return {
        ...state,
        gettingInitialMessages: false,
        frameOwner: action.meta && action.meta.frame_owner,
        upsellMeta: action.statusCode === PAYMENT_REQUIRED && action.meta,
      }
    }
    case MESSAGE_DESTROY_REQUEST:
    case MESSAGE_SHOW_REQUEST: {
      return { ...state, message: null }
    }
    case TOGGLE_SELECTED_MESSAGE: {
      const { messageId } = action
      let newSelectedMessagesIds
      if (state.selectedMessageIds.includes(messageId)) {
        newSelectedMessagesIds = state.selectedMessageIds.filter((id) => id !== messageId)
      } else {
        newSelectedMessagesIds = [...state.selectedMessageIds, messageId]
      }
      return { ...state, selectedMessageIds: newSelectedMessagesIds }
    }
    case DESELECT_ALL_MESSAGES: {
      return { ...state, selectedMessageIds: [] }
    }
    case MESSAGE_DESTROY_MULTIPLE_SUCCESS: {
      const newPages = [...state.pages]
      const deletedMessageIds = action.response
      for (let i = 0; i < newPages.length; i++) {
        const curMessagePage = newPages[i]
        for (let j = 0; j < curMessagePage.messages.length; j++) {
          const curMessage = curMessagePage.messages[j]
          if (deletedMessageIds.includes(curMessage.id)) {
            curMessagePage.messages.splice(j, 1)
            j -= 1
          }
        }
      }
      return {
        ...state,
        selectedMessageIds: [],
        pages: newPages,
      }
    }
    case MESSAGE_DESTROY_SUCCESS: {
      const newPages = [...state.pages]
      for (let i = 0; i < newPages.length; i++) {
        const curMessagePage = newPages[i]
        for (let j = 0; j < curMessagePage.messages.length; j++) {
          const curMessage = curMessagePage.messages[j]
          if (action.response.data.id === curMessage.id) {
            curMessagePage.messages.splice(j, 1)
          }
        }
      }
      return { ...state, message: action.response.data, pages: newPages }
    }
    case CAPTION_UPDATE_SUCCESS:
    case CAPTION_DESTROY_SUCCESS:
    case MESSAGE_SHOW_SUCCESS: {
      return { ...state, message: action.response.data }
    }
    case STARTED_UPLOAD: {
      return { ...state, uploadUrls: [], uploading: true }
    }
    case FINISHED_UPLOAD: {
      return { ...state, uploading: false }
    }
    case NEW_IN_PROGRESS_UPLOAD: {
      const { inProgressUploads } = state
      const { key, assetType } = action
      const frameNames = action.frame_names
      const url = action.get_url
      frameNames.forEach((name) => {
        if (!inProgressUploads[name]) inProgressUploads[name] = {}
        inProgressUploads[name][key] = { url, assetType }
      })
      return { ...state, inProgressUploads }
    }
    case MESSAGE_DESTROY_ALL_SUCCESS: {
      return { ...state, notifications: ['Your frame should be clear soon.'] }
    }
    case LOG_OUT_SUCCESS: {
      return { ...state, ...DEFAULT_STATE }
    }
    default: {
      return state
    }
  }
}
