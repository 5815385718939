export const LOG_IN_REQUEST = 'LOG_IN_REQUEST'
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS'
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE'
export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST'
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'

export const REGISTRATION_REQUEST = 'REGISTRATION_REQUEST'
export const REGISTRATION_SUCCESS = 'REGISTRATION_SUCCESS'
export const REGISTRATION_FAILURE = 'REGISTRATION_FAILURE'

export const JUST_RESET_PASSWORD_CLEAROUT = 'JUST_RESET_PASSWORD_CLEAROUT'

export function register(args) {
  return {
    type: REGISTRATION_REQUEST,
    api: {
      endpoint: 'users',
      authenticated: false,
      types: [REGISTRATION_REQUEST, REGISTRATION_SUCCESS, REGISTRATION_FAILURE],
      data: args,
      method: 'POST',
    },
  }
}

export function logIn(args) {
  const firstTime = !!args.token && !args.resettingPassword
  if (firstTime) {
    args.text_me_the_app = args.textMeTheApp
    args.agreed_to_marketing = args.agreedToMarketing
  }
  return {
    type: LOG_IN_REQUEST,
    api: {
      endpoint: 'sessions',
      authenticated: false,
      types: [LOG_IN_REQUEST, LOG_IN_SUCCESS, LOG_IN_FAILURE],
      data: args,
      method: 'POST',
    },
  }
}

export function logOut(isAdmin) {
  if (!isAdmin) {
    return { type: LOG_OUT_SUCCESS }
  }

  return {
    type: LOG_OUT_REQUEST,
    api: {
      endpoint: 'session',
      authenticated: false,
      isAdmin: true,
      // Use LOG_OUT_SUCCESS for both success and failure so that we clear browser state even if the API call fails
      types: [LOG_OUT_REQUEST, LOG_OUT_SUCCESS, LOG_OUT_SUCCESS],
      method: 'DELETE',
    },
  }
}

export function logInWithGoogle(credential) {
  return {
    type: LOG_IN_REQUEST,
    api: {
      endpoint: 'session',
      isAdmin: true,
      authenticated: false,
      types: [LOG_IN_REQUEST, LOG_IN_SUCCESS, LOG_IN_FAILURE],
      data: { credential },
      method: 'POST',
    },
  }
}
