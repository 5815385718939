import {
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_OUT_SUCCESS,
  REGISTRATION_REQUEST,
  REGISTRATION_SUCCESS,
  REGISTRATION_FAILURE,
  JUST_RESET_PASSWORD_CLEAROUT,
} from '../actions/session'

import { USER_UPDATE_SUCCESS } from '../actions/user'

import { Session } from '../utilities/Session'
import { Analytics } from '../utilities/Analytics'
// import { SafePendo } from '../utilities/SafePendo'

export function session(
  state = {
    loggedIn: Session.isLoggedIn(),
    isAdmin: Session.isAdmin(),
    id: Session.getId(),
    email: Session.getEmail(),
    registering: false,
    loggingIn: false,
    passwordResetToken: null,
    checkYourEmail: false,
    knowsPassword: false,
    justResetPassword: false,
    errors: [],
  },
  action
) {
  switch (action.type) {
    case LOG_IN_REQUEST: {
      return { ...state, loggingIn: true, errors: [] }
    }
    case REGISTRATION_REQUEST: {
      return { ...state, registering: true, errors: [] }
    }
    case USER_UPDATE_SUCCESS: {
      const newEmail = action.response.data.attributes.email
      Session.setEmail(newEmail)
      return { ...state, email: newEmail }
    }
    case LOG_IN_SUCCESS: {
      const { id, attributes } = action.response.data
      const isAdmin = attributes.role === 'admin'
      Session.logIn(id, attributes)
      Analytics.identify(id, attributes.email)
      Analytics.track('Logged In')
      // SafePendo.init()
      return {
        ...state,
        id,
        isAdmin,
        email: attributes.email,
        loggedIn: true,
        registering: false,
        loggingIn: false,
        passwordResetToken: null,
        checkYourEmail: false,
        justResetPassword: action.response.meta?.password_reset,
      }
    }
    case JUST_RESET_PASSWORD_CLEAROUT: {
      return { ...state, justResetPassword: false }
    }
    case REGISTRATION_SUCCESS: {
      const {
        response: { meta, data },
      } = action
      const passwordResetToken = meta.password_reset_token
      const { id, attributes } = data
      const { email } = attributes
      Analytics.identify(id, email)
      Analytics.track('Completed Registration')
      return {
        ...state,
        passwordResetToken,
        id,
        email,
        knowsPassword: meta.knows_password || false,
        checkYourEmail: meta.check_your_email || false,
        loggedIn: false,
        isAdmin: false,
        registering: false,
        loggingIn: false,
      }
    }
    case LOG_IN_FAILURE: {
      return {
        ...state,
        loggedIn: false,
        isAdmin: false,
        registering: false,
        loggingIn: false,
        errors: action.errors,
      }
    }
    case REGISTRATION_FAILURE: {
      return {
        ...state,
        loggedIn: false,
        isAdmin: false,
        registering: false,
        loggingIn: false,
        passwordResetToken: null,
        checkYourEmail: false,
        knowsPassword: false,
        errors: action.errors,
      }
    }
    case LOG_OUT_SUCCESS: {
      Session.logOut()
      return {
        ...state,
        loggedIn: false,
        isAdmin: false,
        id: null,
        email: null,
        registering: false,
        loggingIn: false,
        passwordResetToken: null,
        checkYourEmail: false,
        knowsPassword: false,
        errors: [],
      }
    }
    default:
      return state
  }
}
